import React, { createContext, useEffect, useRef, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useSessionStore } from '@web-app/stores/sessionStore'
import { useApolloClient } from '@web-app/hooks/useApolloClient'
import { useBranch } from '@web-app/hooks/useBranch'
import { useBraze } from '@web-app/hooks/useBraze'
import { sendGTMEvent } from '@next/third-parties/google'
import UAParser from 'ua-parser-js'
import {
  PublishWebEventDocument,
  PublishWebEventInput,
} from '@graphql/react-apollo'

type EventData = Record<string, unknown>

type EventTrackingContextType = {
  trackEvent: (eventName: string, data?: EventData, keyEvent?: boolean) => void
}

export const EventTrackingContext = createContext<
  EventTrackingContextType | undefined
>(undefined)

export const EventTrackingProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { sessionData } = useSessionStore()
  const apolloClient = useApolloClient()
  const { publishBranchEvent } = useBranch()
  const { publishBrazeEvent } = useBraze()

  const eventQueueRef = useRef<
    Array<{ eventName: string; data?: EventData; keyEvent?: boolean }>
  >([])

  // Memoize UAParser result
  const uaResult = useMemo(() => {
    if (sessionData?.userAgent) {
      const parser = new UAParser(sessionData.userAgent)
      return parser.getResult()
    }
    return {}
  }, [sessionData?.userAgent])

  // Process event queue when Apollo Client is ready
  useEffect(() => {
    if (apolloClient) {
      if (eventQueueRef.current.length > 0) {
        eventQueueRef.current.forEach(({ eventName, data, keyEvent }) => {
          internalTrackEvent(eventName, data, keyEvent)
        })
        eventQueueRef.current = []
      }
    }
  }, [apolloClient])

  // Internal function to track events
  const internalTrackEvent = (
    eventName: string,
    data: EventData = {},
    keyEvent = false,
  ) => {
    if (apolloClient) {
      const input: PublishWebEventInput = {
        clientMutationId: uuidv4(),
        event: {
          name: eventName,
          data: {
            ...data,
            ...sessionData?.utmParams,
            ...uaResult,
            browserFingerprint: sessionData?.browserFingerprint,
            sessionId: sessionData?.sessionId,
          },
          requestId: uuidv4(),
        },
      }

      apolloClient
        .mutate({
          mutation: PublishWebEventDocument,
          variables: { input },
        })
        .catch((error: unknown) => {
          console.error('Error publishing web event:', error)
        })
    } else {
      // Queue the event if Apollo Client isn't ready
      eventQueueRef.current.push({ eventName, data, keyEvent })
    }

    // Process key events regardless of Apollo Client readiness
    if (keyEvent) {
      try {
        publishBranchEvent?.(eventName, data)
      } catch (error) {
        console.error('Error publishing Branch event:', error)
      }

      try {
        publishBrazeEvent?.(eventName, data)
      } catch (error) {
        console.error('Error publishing Braze event:', error)
      }

      try {
        sendGTMEvent({ event: eventName, ...data })
      } catch (error) {
        console.error('Error sending GTM event:', error)
      }
    }
  }

  // Public function to track events
  const trackEvent = (
    eventName: string,
    data: EventData = {},
    keyEvent = false,
  ) => {
    internalTrackEvent(eventName, data, keyEvent)
  }

  return (
    <EventTrackingContext.Provider value={{ trackEvent }}>
      {children}
    </EventTrackingContext.Provider>
  )
}
