import React, { createContext, useEffect, useRef, useCallback } from 'react'
import { useSession } from '@web-app/hooks/useSession'

interface BrazeEventProperties {
  [key: string]: unknown
}

interface BrazeContextType {
  publishBrazeEvent: (
    eventName: string,
    properties?: BrazeEventProperties,
  ) => void
}

export const BrazeContext = createContext<BrazeContextType | undefined>(
  undefined,
)

export const BrazeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { sessionData } = useSession()
  const user = sessionData?.user

  // References to track initialization and store the Braze module
  const brazeIsInitializedRef = useRef(false)
  const brazeModuleRef = useRef<typeof import('@braze/web-sdk') | null>(null)
  const eventQueueRef = useRef<
    Array<{ eventName: string; properties?: BrazeEventProperties }>
  >([])

  // Function to set user details
  const setUserDetails = useCallback(() => {
    if (brazeModuleRef.current && user?.id) {
      const { changeUser, getUser } = brazeModuleRef.current

      changeUser(user.id)

      if (user.email) {
        getUser()?.setEmail(user.email)
      }

      if (user.phone) {
        getUser()?.setPhoneNumber(user.phone)
      }
    }
  }, [user])

  useEffect(() => {
    let isMounted = true

    const initializeBraze = async () => {
      try {
        const brazeModule = await import('@braze/web-sdk')
        if (!isMounted) return

        brazeModuleRef.current = brazeModule
        const { initialize, openSession } = brazeModule

        const brazeApiKey = process.env.NEXT_PUBLIC_BRAZE_KEY || ''
        const brazeEndpoint = process.env.NEXT_PUBLIC_BRAZE_ENDPOINT || ''

        if (!brazeApiKey || !brazeEndpoint) {
          console.warn(
            'Braze API key or endpoint is not set in environment variables.',
          )
          return
        }

        initialize(brazeApiKey, {
          baseUrl: brazeEndpoint,
          // enableLogging: true,
        })
        openSession()

        brazeIsInitializedRef.current = true

        // Set user details after initialization
        setUserDetails()

        // Process any queued events after initialization
        if (eventQueueRef.current.length > 0) {
          eventQueueRef.current.forEach(({ eventName, properties }) => {
            brazeModule.logCustomEvent(eventName, properties)
          })
          // Clear the queue
          eventQueueRef.current = []
        }
      } catch (error) {
        console.error('Error initializing Braze SDK:', error)
        // Prevent further attempts
        brazeIsInitializedRef.current = true
        // Optionally clear the event queue
        eventQueueRef.current = []
      }
    }

    initializeBraze()

    return () => {
      isMounted = false
    }
  }, [setUserDetails])

  // Update user info if it changes
  useEffect(() => {
    if (brazeIsInitializedRef.current) {
      setUserDetails()

      // Process any queued events after user info is updated
      if (eventQueueRef.current.length > 0) {
        eventQueueRef.current.forEach(({ eventName, properties }) => {
          brazeModuleRef.current?.logCustomEvent(eventName, properties)
        })
        // Clear the queue
        eventQueueRef.current = []
      }
    }
  }, [user, setUserDetails])

  const publishBrazeEvent = useCallback(
    (eventName: string, properties?: BrazeEventProperties): void => {
      try {
        if (brazeIsInitializedRef.current && brazeModuleRef.current) {
          brazeModuleRef.current.logCustomEvent(eventName, properties)
        } else {
          if (eventQueueRef.current.length < 100) {
            // Queue the event to be sent after initialization
            eventQueueRef.current.push({ eventName, properties })
          } else {
            console.warn('Event queue is full. Discarding event:', eventName)
          }
        }
      } catch (error) {
        console.error(`Failed to publish Braze event: ${eventName}`, error)
      }
    },
    [],
  )

  return (
    <BrazeContext.Provider value={{ publishBrazeEvent }}>
      {children}
    </BrazeContext.Provider>
  )
}
