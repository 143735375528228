import React, { createContext, useEffect, useState } from 'react'
import { useSessionStore } from '@web-app/stores/sessionStore'
import type { BaseSessionData } from '@web-app/lib/ironSession'

export type UserSessionContextType = {
  sessionData: BaseSessionData
  loading: boolean
  refetchSessionData: () => Promise<void>
  setSessionData: (sessionData: BaseSessionData) => void
}

export const UserSessionContext = createContext<
  UserSessionContextType | undefined
>(undefined)

export const UserSessionProvider: React.FC<{
  children: React.ReactNode
  initialSessionData?: BaseSessionData
}> = ({ children, initialSessionData }) => {
  const { sessionData, setSessionData } = useSessionStore()
  const [loading, setLoading] = useState(!initialSessionData)

  const refetchSessionData = async (): Promise<void> => {
    setLoading(true)
    const controller = new AbortController()
    const { signal } = controller

    try {
      const response = await fetch('/api/user', { signal })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data: BaseSessionData = await response.json()
      setSessionData(data)
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        console.warn('Session data fetch aborted')
      } else {
        console.error(`Failed to fetch session data: ${String(error)}`)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (initialSessionData) {
      setSessionData(initialSessionData)
      setLoading(false)
    } else {
      refetchSessionData()
    }
  }, [initialSessionData, setSessionData])

  useEffect(() => {
    const updateScreenSize = async () => {
      if (typeof window !== 'undefined') {
        const { width: screenWidth, height: screenHeight } = window.screen
        try {
          const response = await fetch('/api/set-screen-size', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ screenWidth, screenHeight }),
          })
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }
        } catch (error) {
          console.error('Failed to update screen size:', error)
        }
      }
    }

    updateScreenSize()
  }, [])

  return (
    <UserSessionContext.Provider
      value={{
        sessionData,
        loading,
        refetchSessionData,
        setSessionData,
      }}
    >
      {children}
    </UserSessionContext.Provider>
  )
}
