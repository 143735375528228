import { useContext } from 'react'
import { BrazeContext } from '@web-app/context/BrazeContext'

export const useBraze = () => {
  const context = useContext(BrazeContext)
  if (context === undefined) {
    throw new Error('useBraze must be used within a BrazeProvider')
  }
  return context
}
