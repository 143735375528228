import { create } from 'zustand'
import type { BaseSessionData } from '@web-app/lib/ironSession'

interface SessionStore {
  sessionData: BaseSessionData
  setSessionData: (data: BaseSessionData) => void
}

export const useSessionStore = create<SessionStore>((set) => ({
  sessionData: {
    browserFingerprint: '',
    isAuthenticated: false,
    sessionId: '',
    sessionIdCreated: 0,
    userAgent: '',
    user: undefined,
    utmParams: undefined,
  },
  setSessionData: (data) => set({ sessionData: data }),
}))
