import { useMemo } from 'react'
import { useSessionStore } from '@web-app/stores/sessionStore'
import { initializeApollo } from '@web-app/lib/apolloClient'
import type { NormalizedCacheObject } from '@apollo/client'

export const useApolloClient = (
  initialState: NormalizedCacheObject = {},
  browserFingerprint?: string,
  sessionId?: string,
  userAgent?: string,
) => {
  const { sessionData } = useSessionStore()
  const { user } = sessionData
  const token = user?.token

  const apolloClient = useMemo(() => {
    return initializeApollo(
      initialState,
      browserFingerprint,
      sessionId,
      userAgent,
      token,
    )
  }, [initialState, browserFingerprint, sessionId, userAgent, token])

  return apolloClient
}
