import React from 'react'
import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { Toaster } from 'react-hot-toast'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { GoogleTagManager } from '@next/third-parties/google'
import { UserSessionProvider } from '@web-app/context/UserSessionContext'
import { BranchProvider } from '@web-app/context/BranchContext'
import { BrazeProvider } from '@web-app/context/BrazeContext'
import { EventTrackingProvider } from '@web-app/context/EventTrackingContext'
import { useApolloClient } from '@web-app/hooks/useApolloClient'
import { inter, montserrat } from '@web-app/styles/fonts'
import { ErrorBoundary } from '@ui/components/ErrorBoundary'
import type { NormalizedCacheObject } from '@apollo/client'
import type { BaseSessionData } from '@web-app/lib/ironSession'

// Load Shared Tailwind CSS
import '@ui/styles/tailwind.css'

function WebApp({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <AppProviders pageProps={pageProps}>
        <Component {...pageProps} />
      </AppProviders>
      <Toaster position="top-center" />
      <Analytics />
      <SpeedInsights />
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID || ''} />
    </ErrorBoundary>
  )
}

export interface PageProps {
  initialApolloState?: NormalizedCacheObject
  sessionData?: BaseSessionData
  [key: string]: unknown
}

interface AppProvidersProps {
  children: React.ReactNode
  pageProps: PageProps
}

const AppProviders: React.FC<AppProvidersProps> = ({ children, pageProps }) => {
  const browserFingerprint = pageProps.sessionData?.browserFingerprint as string
  const sessionId = pageProps.sessionData?.sessionId as string
  const userAgent = pageProps.sessionData?.userAgent as string

  const apolloClient = useApolloClient(
    pageProps.initialApolloState || {},
    browserFingerprint,
    sessionId,
    userAgent,
  )

  return (
    <UserSessionProvider initialSessionData={pageProps.sessionData}>
      <BranchProvider>
        <BrazeProvider>
          <ApolloProvider client={apolloClient}>
            <EventTrackingProvider>
              <div
                className={`${inter.variable} ${montserrat.variable} font-inter`}
              >
                {children}
              </div>
            </EventTrackingProvider>
          </ApolloProvider>
        </BrazeProvider>
      </BranchProvider>
    </UserSessionProvider>
  )
}

export default WebApp
