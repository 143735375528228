import React, { createContext, useEffect, useRef, useCallback } from 'react'
import { useSession } from '@web-app/hooks/useSession'
import type { Branch } from '@web-app/types'

interface BranchEventProperties {
  [key: string]: unknown
}

interface BranchContextType {
  publishBranchEvent: (
    eventName: string,
    eventProperties?: BranchEventProperties,
  ) => void
  trackPageView: () => void
}

export const BranchContext = createContext<BranchContextType | undefined>(
  undefined,
)

export const BranchProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { sessionData } = useSession()
  const user = sessionData?.user

  const branchRef = useRef<Branch | null>(null)
  const isInitializedRef = useRef(false)
  const eventQueueRef = useRef<
    Array<{ eventName: string; eventProperties?: BranchEventProperties }>
  >([])

  // Function to set user details
  const setUserDetails = useCallback(() => {
    if (branchRef.current && user?.id) {
      branchRef.current.setIdentity(user.id)
    }
  }, [user])

  // Initialize Branch SDK
  useEffect(() => {
    let isMounted = true

    const initializeBranch = () => {
      if (typeof window === 'undefined') {
        return
      }

      const checkBranchLoaded = () => {
        if (!isMounted) return

        if (window.branch) {
          branchRef.current = window.branch
          isInitializedRef.current = true

          // Set user details after initialization
          setUserDetails()

          // Process any queued events
          const branchInstance = branchRef.current
          if (branchInstance) {
            eventQueueRef.current.forEach(({ eventName, eventProperties }) => {
              branchInstance.logEvent(eventName, eventProperties)
            })
            // Clear the queue
            eventQueueRef.current = []
          }
        } else {
          // Wait and check again every 250ms
          setTimeout(checkBranchLoaded, 250)
        }
      }

      checkBranchLoaded()

      return () => {
        isMounted = false
      }
    }

    initializeBranch()
  }, [setUserDetails])

  // Update user identity when user data changes
  useEffect(() => {
    if (isInitializedRef.current) {
      setUserDetails()
    }
  }, [user, setUserDetails])

  // Publish Branch event
  const publishBranchEvent = useCallback(
    (eventName: string, eventProperties?: BranchEventProperties): void => {
      if (isInitializedRef.current && branchRef.current) {
        branchRef.current.logEvent(eventName, eventProperties)
      } else {
        // Queue the event to be sent after initialization
        eventQueueRef.current.push({ eventName, eventProperties })
      }
    },
    [],
  )

  // Track page view
  const trackPageView = useCallback((): void => {
    if (isInitializedRef.current && branchRef.current) {
      branchRef.current.track('pageview')
    } else {
      // Queue the pageview event to be sent after initialization
      eventQueueRef.current.push({ eventName: 'pageview', eventProperties: {} })
    }
  }, [])

  return (
    <BranchContext.Provider
      value={{
        publishBranchEvent,
        trackPageView,
      }}
    >
      {children}
    </BranchContext.Provider>
  )
}
